import Service from "../Service";

const resource = "operationcommercial/";

export default {
    listoc(parameters, requestID) {
        return Service.post(resource + "listoc", parameters, {
            params: { requestID: requestID },
        });
    },

    generarDevolucion(datos) {
        return Service.post(resource + "generarDevolucion", datos, { responseType: "blob" });
    },

    // changePassword(password, usr) {
    //     usr.UsrPassword = password;
    //     return Service.post(menu + "ApplicationChangePassword", usr, {
    //         params: { requestID: usr.UsrID },
    //     });
    // },
};