<!-- <template>
  <div class="form-container">
    <v-container>
      <v-row>
        <v-col cols="12">
          <s-toolbar dark label="Formulario de Constancia de Devolución"></s-toolbar>
          <template>
            <v-container class="pb-0">
              <v-row class="justify-center">
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="DNI" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Nombre del Cliente" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-select-definition label="Causa" :options="causas" v-model="formData.causa"
                    placeholder="Seleccione una causa" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-select-definition label="Contrato" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-select-definition label="Opcion Pago" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Numero de Contrato" />
                </v-col>

                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Domicilio" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Distrito" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Provincia" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Departamento" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="N° Lote" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-select-definition label="Proyecto" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text type="number" v-model="formData.monto" step="0.01" required label="Monto" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-date label="Fecha Devolucion"></s-date>
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="N° Numero de Cuenta Bancaria" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Banco" />
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-col>
      </v-row>
    </v-container>    
  </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        causas: [
          { value: "resolucion_contrato", label: "Resolución de contrato" },
          { value: "devolucion_cash", label: "Devolución de cash" },
          { value: "error_transferencia", label: "Error en la transferencia" },
        ],
        formData: {
          causa: null,         
        },
      };
    },
    methods: {
      async submitForm() {
        try {
          const response = await fetch("https://tu-backend/api/generar-docx", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(this.formData),
          });
  
          if (!response.ok) {
            throw new Error("Error al generar el documento");
          }
  
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
  
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "constancia_devolucion.docx");
          document.body.appendChild(link);
          link.click();
          link.remove();
  
          this.documentGenerated = true; 
          alert("Documento generado exitosamente");
        } catch (error) {
          console.error(error);
          alert("Hubo un problema al generar el documento");
        }
      },
      printDocument() {
        window.print(); 
      },
    },
  };
  </script>
  <style>
  .tran {
      opacity: 0.7 !important;
  }
  </style> -->

  <template>
    <div class="form-container">
      <v-container>
        <v-row>
          <v-col cols="12">
            <s-toolbar dark label="Formulario de Constancia de Devolución"></s-toolbar>
            <v-container class="pb-0">
              <v-row class="justify-center">
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="DNI" v-model="formData.dni" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Nombre del Cliente" v-model="formData.cliente" />
                </v-col>
                <!-- <v-col cols="12" md="6" class="s-col-form">
                  <v-select :items="causas" v-model="formData.causa" item-text="label" item-value="value" label="Causa"
                    placeholder="Seleccione una causa" />
                </v-col> -->
                <v-col cols="12" md="6" class="s-col-form">
                  <s-select :items="causas" v-model="formData.causa" item-text="label" item-value="label" label="Causa"
                    placeholder="Seleccione una causa" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-select :items="contratos" v-model="formData.contrato" item-text="label" item-value="label"
                    label="Contrato" placeholder="Seleccione un contrato" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-select :items="pagos" v-model="formData.opcionPago" item-text="label" item-value="label"
                    label="Opcion de Pago" placeholder="Seleccione una opción de Pago" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Numero de Contrato" v-model="formData.numeroContrato" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Domicilio" v-model="formData.domicilio" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Distrito" v-model="formData.distrito" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Provincia" v-model="formData.provincia" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Departamento" v-model="formData.departamento" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="N° Lote" v-model="formData.lote" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-select :items="proyectos" v-model="formData.proyecto" item-text="label" item-value="label"
                    label="Proyecto" placeholder="Seleccione un Proyecto" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                <s-text label="Monto" type="text" v-model="formData.monto"
                    placeholder="Ingrese un monto con decimales (ej. 123.45)" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-date label="Fecha Devolución" v-model="formData.fechaDevolucion" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="Banco" v-model="formData.banco" />
                </v-col>
                <v-col cols="12" md="6" class="s-col-form">
                  <s-text label="N° Numero de Cuenta Bancaria" v-model="formData.cuentaBancaria" />
                </v-col>
                <!-- Botones -->
                <v-col cols="12" class="s-col-form text-center">
                  <v-btn color="primary" @click="submitForm">Generar Documento</v-btn>
                  <!-- <v-btn color="green" @click="printDocument">Imprimir Formulario</v-btn> -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  
  <script>

import _sOC from "@/services/CustomerSupport/AtcOperationCommercial";


  export default {
    props: {
      value: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        formattedMonto: this.value !== null ? this.value.toFixed(2) : "",
        // Opciones de la causa
        causas: [
          { value: "Resolución de contrato", label: "Resolución de contrato" },
          { value: "Devolución de cash", label: "Devolución de cash" },
          { value: "Error en la transferencia", label: "Error en la transferencia" },
        ],
        contratos: [
          { value: "Contrato de compraventa", label: "Contrato de compraventa" },
          { value: "Contrato de compraventa de bien futuro", label: "Contrato de compraventa de bien futuro" },
          { value: "Contrato de compromiso de compraventa", label: "Contrato de compromiso de compraventa" },
        ],
        pagos: [
          { value: "Financiado", label: "Financiado" },
          { value: "Contado", label: "Contado" },
        ],
        proyectos: [
          { value: "1", label: "LA ALAMEDA DE MIRAFLORES COUNTRY – IMP" },
          { value: "2", label: "ALAMEDA COUNTRY CLUB" },
          { value: "3", label: "MIRAFLORES BOULEVARD PARK PLAZA" },
          { value: "4", label: "MIRAFLORES BOULEVARD PARK PLAZA CUADRANTE 2" },
          { value: "5", label: "MIRAFLORES COUNTRY CLUB 2" },
          { value: "6", label: "RESIDENCIAL ACACIAS DE MIRAFLORES" },
          { value: "7", label: "LA ARBOLEDA DE MIRAFLORES COUNTRY" },
        ],
        formData: {
          dni: "",
          cliente: "",
          causa: null,
          contrato: null,
          opcionPago: null,
          monto: null,
          fechaDevolucion: null,
          proyecto: null,
          numeroContrato: null,
          domicilio: null,
          cuentaBancaria: null,
          banco:null,
          distrito:null,
          provincia:null,
          departamento:null,
          lote:null
        },
        generatedDocUrl: null,
        validations: {
          dni: true,
          cliente: true,
          causa: true,
          contrato: true,
          opcionPago: true,
          monto: true,
          // fechaDevolucion: true,
          proyecto: true,
          numeroContrato: true,
          domicilio: true,
          cuentaBancaria: true,
          banco: true,
          distrito: true,
          provincia: true,
          departamento: true,
          lote: true
        },
      };
    },
    watch: {
      value(newVal) {
        this.formattedMonto = newVal !== null ? newVal.toFixed(2) : "";
      },
    },
    methods: {
      validateDecimal(event) {
        const input = event.target.value;
        const decimalRegex = /^[0-9]*\.?[0-9]*$/;

        if (decimalRegex.test(input)) {
          // Permitir el valor si es válido
          this.formattedMonto = input;
          const numericValue = parseFloat(input);
          this.$emit("input", isNaN(numericValue) ? null : numericValue);
        } else {
          // Revertir al valor anterior si no es válido
          event.target.value = this.formattedMonto;
        }
      },
      validate() {
        this.validations.dni = this.formData.dni.trim() !== "";
        this.validations.cliente = this.formData.cliente.trim() !== "";
        this.validations.numeroContrato = this.formData.numeroContrato.trim() !== "";
        this.validations.domicilio = this.formData.domicilio.trim() !== "";
        this.validations.distrito = this.formData.distrito.trim() !== "";
        this.validations.provincia = this.formData.provincia.trim() !== "";
        this.validations.departamento = this.formData.departamento.trim() !== "";
        this.validations.lote = this.formData.lote.trim() !== "";
        this.validations.monto = this.formData.monto.trim() !== "";
        this.validations.banco = this.formData.banco.trim() !== "";
        this.validations.cuentaBancaria = this.formData.cuentaBancaria.trim() !== ""; 
        
      },
      async submitForm() {
        try {
          this.validate();
          if (!this.validations.dni) {
            alert("El dni del cliente es obligatorio.");
            return;
          }
          if (!this.validations.cliente) {
            alert("El nombre del cliente es obligatorio.");
            return;
          }          
          if (!this.validations.numeroContrato) {
            alert("El numero de contrato es obligatorio.");
            return;
          }  
          if (!this.validations.domicilio) {
            alert("El domicilio del cliente es obligatorio.");
            return;
          }
          if (!this.validations.distrito) {
            alert("El distrito es obligatorio.");
            return;
          }
          if (!this.validations.provincia) {
            alert("La provincia es obligatoria.");
            return;
          }
          if (!this.validations.departamento) {
            alert("El departamento es obligatorio.");
            return;
          }
          if (!this.validations.lote) {
            alert("El numero de lote es obligatorio.");
            return;
          }
          if (!this.validations.monto) {
            alert("El nomto a devolver es obligatorio.");
            return;
          }
          if (!this.validations.banco) {
            alert("El nombre del banco es obligatorio.");
            return;
          }
          if (!this.validations.cuentaBancaria) {
            alert("La cuenta bancaia del cliente es obligatorio.");
            return;
          }          
          
          const response = await _sOC.generarDevolucion(this.formData);

          const blob = await response.data;

          // Crear la URL del archivo descargable
          this.generatedDocUrl = window.URL.createObjectURL(blob);

          // Crear un enlace para descargar el archivo
          const link = document.createElement("a");
          link.href = this.generatedDocUrl;
          link.setAttribute("download", "constancia_devolucion.docx");
          document.body.appendChild(link);
          link.click();
          link.remove();

        } catch (error) {
          console.error(error);
          alert("Hubo un problema al generar el documento");
        }
      },
   
      printDocument() {
        if (this.generatedDocUrl) {
          const printWindow = window.open(this.generatedDocUrl, "_blank");
          printWindow.print();
        } else {
          alert("Primero genere el documento para imprimirlo");
        }
      },
  
    },
  };
  </script>
  
  <style>
  .form-container {
    margin: 20px;
  }
  .s-col-form {
    margin-bottom: 15px;
  }
  </style>
  
  
  <!-- <style>
  .form-container {
    width: 50%;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  form div {
    margin-bottom: 10px;
  }
  label {
    display: block;
    margin-bottom: 5px;
  }
  input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  button {
    margin-right: 10px;
    padding: 10px 20px;
  }
  </style> -->