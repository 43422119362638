<template>
<div>

    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>
    <v-card class="mt-2">

        <s-toolbar style="font-family: Calibri;" label="INMOBILIARIA MIRAFLORES PERÚ SAC" dark close @close="$emit('close')" save @save="save()">

        </s-toolbar>

        <v-divider></v-divider>

        <v-card outlined>
            <v-col>
                <v-row>
                    <v-card width="100%" outlined style="background:rgba(255,255,255,0.9); padding:0px;margin:0px;">
                        <v-card-title>
                            <v-row>
                                <v-col class='d-flex justify-center '>
                                    <h4 style="font-size: 12px !important; line-height: 1.1" class="s-color-title d-none d-sm-flex text-center">
                                        Datos del Visitante
                                    </h4>
                                </v-col>
                            </v-row>
                        </v-card-title>
                    </v-card>
                    <v-col cols="12" lg="12" md="12">
                        <v-col class="s-color-title mt-10" style="" cols="12">

                            <h1 v-if="person.NtpName" style="font-size: 40px !important; line-height: 1.1" class="s-color-title d-none d-sm-flex text-center">
                                <v-row justify="center">
                                    {{
                        person.NtpName
                      }}</v-row>
                            </h1>
                            <h1 v-if="person.NtpName" style="font-size: 32px !important" class="d-flex d-sm-none s-color-title text-center">
                                {{
                      person.NtpName
                    }}
                            </h1>

                            <h1 v-else style="font-size: 40px !important" class="s-color-title text-center">
                                CONTROL DE VISITAS CLIENTE
                            </h1>

                            <h1 style="font-size: 32px !important" class="d-none d-sm-flex s-color-title text-center">
                                <v-col class="d-flex justify-center" style="padding:0px">{{
                      person.PrsDocumentNumber
                    }}</v-col>
                            </h1>
                            <h1 style="font-size: 24px !important" class="text-center d-sm-none s-color-title">
                                {{ person.PrsDocumentNumber }}
                            </h1>
                        </v-col>
                    </v-col>
                </v-row>
                <v-row>
                    <v-card width="100%" outlined style="background:rgba(255,255,255,0.9); padding:0px;margin:0px;">
                        <v-card-title>
                            <v-row>
                                <v-col class='d-flex justify-center '>
                                    <h4 style="font-size: 12px !important; line-height: 1.1" class="s-color-title d-none d-sm-flex text-center">
                                        Motivo de la visita
                                    </h4>
                                </v-col>
                            </v-row>
                        </v-card-title>
                    </v-card>
                     <v-col cols="12" lg="12" md="12" class="pt-0 pb-2">
                        <s-select-definition autocomplete full clearable label="Motivo Visita" :def="1185" return-object add v-model="ObjTypeVisit"></s-select-definition>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" class="pt-0 pb-2">
                        <c-select-area  :disabled="disabled" full :typeArea="3" no-default clearable label="Área Visitar" v-model="item.AreID"></c-select-area>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" class="pt-0 pb-0">
                        <s-select-definition  :disabled="disabled2" autocomplete full clearable label="Responsable" :def="1186" add v-model="item.TypeResponsible"></s-select-definition>
                    </v-col>
                   <!-- sea agregan nuevos campos a registrar para que viaje a evolta -->
                   <v-col cols="12" lg="6" md="6" class="pt-0 pb-0">
                        <s-text label="Email" v-model="item.email"/>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" class="pt-0 pb-0">
                        <s-text type="number" label="Nro. Celular *" v-model="item.Celular"/>
                    </v-col>
                    <!-- <v-col cols="12" lg="12" md="12" class="pt-0 pb-2">
                        <s-select-definition autocomplete full clearable label="Como se Enteró" return-object add v-model="item.comoseentero"></s-select-definition>
                    </v-col> -->
                    <v-col cols="12" class="pt-0 pb-2">
                        <s-textarea label="Descripción" v-model="item.VstDescription"></s-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-card>
</div>
</template>

<script>
/* import cSelectArea from '../../../components/Utils/cSelectArea.vue'; */
import _ControlAccessService from "@/services/Management/ControlAccess";
import SText from '../../../components/Utils/SText.vue';

import _sReferrals from '@/services/WebSiteIMP/WebReferralsService.js'

export default {
    components: {
       
        SText 
    },
    props: {
        person: null
    },
    data() {
        return {
            messageProcessing: "",
            processing: false,
            item: {
                AreID: null,
                TypeResponsible: null,
                TypeVisit: null,
                VstDescription: null,
                Celular: null,
                email: null

            },
            // comoseentero: [
            //     { value: "BODEGAS REFERIDOS", label: "BODEGAS REFERIDOS" },
            //     { value: "BODEGAS REFERIDOS", label: "BODEGAS REFERIDOS" },
            //     { value: "CONVENIOS", label: "CONVENIOS" },
            //     { value: "E-MAIL MARKETING", label: "E-MAIL MARKETING" },
            //     { value: "BODEGAS REFERIDOS", label: "BODEGAS REFERIDOS" },
            //     { value: "BODEGAS REFERIDOS", label: "BODEGAS REFERIDOS" },
            //     { value: "BODEGAS REFERIDOS", label: "BODEGAS REFERIDOS" },
            //     { value: "BODEGAS REFERIDOS", label: "BODEGAS REFERIDOS" },
            //     { value: "BODEGAS REFERIDOS", label: "BODEGAS REFERIDOS" },
            //     { value: "BODEGAS REFERIDOS", label: "BODEGAS REFERIDOS" },
            //     { value: "BODEGAS REFERIDOS", label: "BODEGAS REFERIDOS" },
            //     { value: "BODEGAS REFERIDOS", label: "BODEGAS REFERIDOS" },
            //     { value: "BODEGAS REFERIDOS", label: "BODEGAS REFERIDOS" },
            //     { value: "BODEGAS REFERIDOS", label: "BODEGAS REFERIDOS" },
            //     { value: "BODEGAS REFERIDOS", label: "BODEGAS REFERIDOS" },
            //     { value: "BODEGAS REFERIDOS", label: "BODEGAS REFERIDOS" },
            // ],
            ObjTypeVisit:null,
            disabled:false,
            disabled2:false,

            itemEv: {
                IdTipoPortal: 12,
                IdProyecto: 1587,
                IdTipoDocumento: 1,
                NroDocumento: "",
                Nombres: "",
                Apellidos: "",
                Correo: "",
                Celular: "",
                Comentario: "",
                IncluyeUtm: true,
                utm_campaign: "referidos",
                utm_content: "referidos",
                utm_medium: "referidos",
                utm_source: "referidos",
                utm_term: "referidos",
                Lead_Referido: false,
                Nombres_referido: "",
                Apellidos_referido: "",
                Dni_referido: "",
                Celular_referido: "",
            },
        };
    },

    watch:{
        ObjTypeVisit(){
            this.item.AreID = this.ObjTypeVisit !== null? parseInt(this.ObjTypeVisit.DedHelper.split(',')[0]) > 0 ? parseInt(this.ObjTypeVisit.DedHelper.split(',')[0]) : 0 : null
            this.item.TypeResponsible = this.ObjTypeVisit !== null? parseInt(this.ObjTypeVisit.DedHelper.split(',')[1]) > 0 ? parseInt(this.ObjTypeVisit.DedHelper.split(',')[1]) : 0 : null

            console.log("aqui",this.item,this.ObjTypeVisit)
            console.log("DedID",this.ObjTypeVisit.DedID)

            if(this.item.AreID > 0){
                this.disabled = true
            }else{
                this.disabled = false
            }

            if(this.item.TypeResponsible > 0){
                this.disabled2 = true
            }else{
                this.disabled2 = false
            }
        }
    },

    methods: {
        save() {

            if(this.ObjTypeVisit == null){
                this.$fun.alert("Seleccione Motivo de Visita", "warning")
                return
            }

            if(this.item.AreID == null || this.item.AreID <= 0){
                this.$fun.alert("Seleccione Área a Visitar", "warning")
                return
            }

            if(this.item.TypeResponsible == null || this.item.TypeResponsible <= 0){
                this.$fun.alert("Seleccione Responsable", "warning")
                return
            }

            if(this.item.Celular == null || this.item.Celular == ""){
                this.$fun.alert("Ingrese Número de Celular", "warning")
                return
            }    
            if(this.item.email == null || this.item.email == ""){
                this.item.email = "sinemail@gmail.com"
            }        

            this.item.TypeVisit = this.ObjTypeVisit !== null? parseInt(this.ObjTypeVisit.DedValue) : null
            console.log("this.person.NtpName", this.person.NtpName)
            this.item.NtpName = this.person.NtpName
            this.item.PrsDocumentNumber = this.person.PrsDocumentNumber
            this.item.SecStatus =1
            this.item.UsrCreateID = this.$fun.getUserID()
            if(this.ObjTypeVisit.DedID = 2567){
                let texto = this.person.NtpName;
                let palabras = texto.split(" ");
                let numApellidos = palabras.length > 2 ? 2 : palabras.length;
                let apellidos = palabras.slice(0, numApellidos).join(" ");
                let nombres = palabras.slice(numApellidos).join(" ");

                this.itemEv.NroDocumento =  this.person.PrsDocumentNumber
                this.itemEv.Nombres = nombres
                this.itemEv.Apellidos = apellidos
                this.itemEv.Correo = this.item.email
                this.itemEv.Celular = this.item.Celular
                this.itemEv.utm_campaign = "Edificio IMP"
                this.itemEv.utm_content = "Edificio IMP"
                this.itemEv.utm_medium = "Edificio IMP"
                this.itemEv.utm_source = "Edificio IMP"
                this.itemEv.utm_term = "Edificio IMP"
                this.itemEv.Lead_Referido = false
                this.itemEv.codVendedor = this.item.TypeResponsible
            }
            console.log("save", this.item)
            console.log("save itemEv", this.itemEv)
            
            this.$fun.alert("¿Seguro de registrar visita?", "question", false).then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true
                    _ControlAccessService.saveVisit(this.item, this.$fun.getUserID()).then(resp => {
                            if (resp.status == 200) {
                                this.$fun.alert("Registrado correctamente", "success", false)
                                this.processing = false
                                this.$emit('close')
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        });
                    if(this.ObjTypeVisit.DedID = 2567){
                       _sReferrals.save(this.itemEv, 42).then(resp => {
                       if (resp.status == 200) {
                           if(resp.data == "000"){
                            //     this.$fun.alert("Registrado correctamente", "success", false)
                            //    this.$router.go(0);
                            }else{
                                this.$fun.alert("[IMP]. Error al registrar datos a Evolta, vuelva a intentarlo. Si el problema persiste favor comunicarse con T.I.", "error", false)
                           }
                            
                       }
                    })
                   }
                }
            })

        }
    },
};
</script>
