import { render, staticRenderFns } from "./LoginCustomer.vue?vue&type=template&id=60b414cd&scoped=true&"
import script from "./LoginCustomer.vue?vue&type=script&lang=js&"
export * from "./LoginCustomer.vue?vue&type=script&lang=js&"
import style0 from "./LoginCustomer.vue?vue&type=style&index=0&id=60b414cd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60b414cd",
  null
  
)

export default component.exports